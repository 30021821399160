import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import PropTypes from 'prop-types';
import { CudaDialogTitle, ActionButton } from '../CudaDialog';

const EditModal = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
  children,
  title,
  closeBtnText,
  contentClass,
  saveBtnText,
  disabled,
  customClasses,
  ...other
}) => (
  <Dialog
    open={showModal}
    maxWidth={false}
    onEscapeKeyDown={closeDialog}
    classes={customClasses}
    {...other}
  >
    <CudaDialogTitle onClose={closeDialog} data-test-id="edit-modal-title">
      {translate(title)}
    </CudaDialogTitle>
    <DialogContent classes={customClasses}>
      <div
        className={classNames(classes.content, contentClass)}
        data-test-id="edit-modal-content"
      >
        {children}
      </div>
    </DialogContent>
    <DialogActions>
      <div data-test-id="edit-modal-actions">
        <ActionButton
          variant="contained"
          color="secondary"
          onClick={closeDialog}
          data-test-id="editCancel"
        >
          {closeBtnText}
        </ActionButton>
        {buttonAction && (
          <ActionButton
            variant="contained"
            color="primary"
            data-test-id="editSave"
            disabled={disabled}
            onClick={buttonAction}
          >
            {saveBtnText}
          </ActionButton>
        )}
      </div>
    </DialogActions>
  </Dialog>
);

const styles = theme => ({
  content: {
    width: '750px',
  },
});

EditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  disabled: PropTypes.bool,
};

EditModal.defaultProps = {
  closeBtnText: translate('shared.cancel'),
  saveBtnText: translate('shared.save'),
};

export default withStyles(styles, { name: 'BasicFilteringEditModalStyles' })(
  EditModal
);
