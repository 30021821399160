import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import { BasicFilterIcon } from './icons';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from './CudaPaper';

import SectionHeader from './SectionHeader';
import SearchBar from './SearchBar';
import BasicFilterTable from '../containers/basic_filtering/Table';
import NetworkWizard from '../containers/basic_filtering/NetworkWizard';
import Tooltip from '@material-ui/core/Tooltip';

import { Mixpanel } from '../lib/Mixpanel';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    margin: 0,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  helpLink: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

class BasicFilter extends Component {
  openModal = event => {
    const { resetConfig } = this.props;
    Mixpanel.track('DNS Filtering / Add Location', {});
    resetConfig();
    this.props.openModal();
  };

  closeModal = event => {
    this.props.closeModal();
  };

  componentDidMount = () => {
    let rule = '';
    if (this.props.location.search) {
      let matches = this.props.location.search.match(/rule=([^&]*)/);

      if (matches.length > 1) {
        rule = matches[1] || 'default';
      }
    }
    this.props.initData(rule);
  };

  render() {
    const {
      classes,
      query,
      updateSearch,
      createPolicy,
      locationConfig,
      modalOpen,
      readOnly,
    } = this.props;

    return (
      <DocumentTitle title={translate('components.basicFiltering.page_title')}>
        <div>
          <SectionHeader
            icon={<BasicFilterIcon />}
            title={translate('components.basicFiltering.title')}
          >
            <Translate
              value="components.basicFiltering.headerText"
              dangerousHTML
              linkClass={classes.helpLink}
            />
          </SectionHeader>
          <Grid id="basic-filtering" spacing={2} container>
            <Grid item xs={12} md={8} lg={6}>
              <SearchBar
                data-test-id="basicFilteringSearch"
                height="40px"
                autoFocus={false}
                disabled={false}
                value={query}
                onChange={value => {
                  updateSearch(value);
                }}
              />
            </Grid>
            <Grid className={classes.buttonWrapper} item xs={12} md={4} lg={6}>
              <Tooltip
                title={translate('components.basicFiltering.readOnly')}
                disableHoverListener={!readOnly}
              >
                <span>
                  <Button
                    id="add-network-btn"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={this.openModal}
                    disabled={readOnly}
                  >
                    {translate('components.basicFiltering.create')}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <BasicFilterTable disabled={readOnly} />
              </Paper>
            </Grid>
          </Grid>
          <NetworkWizard
            locationConfig={locationConfig}
            loading={this.props.loading}
            showModal={modalOpen}
            buttonAction={() => {
              createPolicy();
            }}
            closeDialog={() => {
              this.closeModal();
            }}
          />
        </div>
      </DocumentTitle>
    );
  }
}

BasicFilter.propTypes = {
  query: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  createPolicy: PropTypes.func.isRequired,
  resetConfig: PropTypes.func.isRequired,
  locationConfig: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default withStyles(styles)(BasicFilter);
