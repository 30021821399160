import React from 'react';
import PropTypes from 'prop-types';
import EditModal from '../modal/EditModal';
import ExceptionPolicy from '../../containers/basic_filtering/ExceptionPolicy';

const ExceptionModal = ({
  title,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
}) => (
  <EditModal
    title={title}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
  >
    <ExceptionPolicy />
  </EditModal>
);

ExceptionModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ExceptionModal;
