import React from 'react';
import PropTypes from 'prop-types';
import EditModal from '../modal/EditModal';
import CategoryPolicy from '../../containers/basic_filtering/CategoryPolicy';

const CategoryModal = ({
  title,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
}) => (
  <EditModal
    title={title}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
  >
    <CategoryPolicy />
  </EditModal>
);

CategoryModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CategoryModal;
